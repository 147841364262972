<template>
  <banner-block jump-cat></banner-block>
  <div class="cat-background cat-background-1">
    <img src="../assets/cat-background-1.png" />
  </div>
  <div class="cat-background cat-background-2">
    <img src="../assets/cat-background-2.png" />
  </div>
  <div class="middle-block next-era">
    <div class="content">
      <div class="buttons">
        <button @click="onClickSupportUs">支持我們▶</button>
        <button @click="onClickSignUp">活動報名▶</button>
      </div>
      <article>
        <h2>年會主題<br />NEXT ERA</h2>
        <p>
          以下個世代為主軸，在呈現量子力學的開創與前瞻性之餘，也象徵了邁入第二屆的 QRACON 將擁有更為成熟的全新面貌。<br />
          同時邀請各地的量子力學愛好者一同研討、一同交流、一同共襄盛舉 讓我們都能成為個領域向前一步的關鍵角色。<br /><br />
        </p>
        <h3><mark>或許下個世代的創造，就在你我之間發生。</mark></h3>
      </article>
    </div>
  </div>
  <div class="middle-block about-us">
    <div class="content">
      <article>
        <h2>關於 QRACON<br />about US</h2>
        <p>
          學生量子電腦年會(Quantum Revolution Assembly CONvention )，
          以學生為主自發起的年會活動如今已邁入第二屆，也持續努力促進量子科技的學術界、產業界與教育端的交流與融合。
          我們堅信「三人行必有我師焉」，透過這個年會的舞台，學生們可以相互啟發、分享知識，並與學術界和產業界的專業人士互動交流。
          這不僅提供學生學習和成長的機會，也促進了不同領域之間的合作和創新。加入我們的年會，一同開啟量子電腦的未來！
        </p>
        <ul>
          <li>Q (Quality) : 高品質演講</li>
          <li>R (Recreational) : 寓教於樂的遊戲</li>
          <li>A (Advocating) : 推廣量子比賽</li>
          <li>C (Competitions) : 促進教育的交流</li>
          <li>O (Optimising) : 透過海報和遊戲來促進量子教育</li>
          <li>N (Narrowing) : 縮短高中與大學端的隔閡</li>
        </ul>
      </article>
    </div>
  </div>
</template>

<script>
import BannerBlock from '../components/BannerBlock.vue'

export default {
  name: 'IndexView',
  components: { BannerBlock },
  methods: {
    onClickSupportUs () {
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSf2rdnlpdKz0FVXvkH6TquzFrx4gO7_imePgWpMhZUVaUWxAA/viewform?embedded=true');
    },
    onClickSignUp () {
      window.open('https://qracon2024y2nd.kktix.cc/events/9df2b2ca-copy-1');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables.scss';

.cat-background {
  position: fixed;
  z-index: -1;

  &.cat-background-1 {
    right: 0;
    top: 25%;
    width: 25%;
  }

  &.cat-background-2 {
    left: 0;
    top: 50%;
    width: 25%;
  }

  > img {
    width: 100%;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}

.middle-block {

  > .content {
    max-width: 720px;
    margin: auto;
    padding-bottom: 2rem;

    > article {
      > h2 {
        position: relative;
  
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -3rem;
          background-color: #FEEA0F;
          height: 100%;
          width: 2rem;
        }
      }
  
      > p {
        line-height: 2.5rem;
      }
  
      > h3 {
        font-size: 1.5rem;
      }
  
      &:not(:last-child) {
        margin-bottom: 5rem;
      }
    }
  }


  &.next-era {
    > .content {
      > .buttons {
        display: flex;
        margin-bottom: 3rem;
  
        button {
          flex: auto;
          background-color: #FEEA0F;
          font-weight: bold;
          font-size: 2.5rem;
          padding: 1rem;
          box-shadow: 5px 5px black;
          cursor: pointer;
  
          &:first-child {
            margin-right: 0.5rem;
          }
  
          &:last-child {
            margin-left: 0.5rem;
          }
  
          &:hover {
            background-color: black;
            color: #FEEA0F;
            box-shadow: 5px 5px #FEEA0F;
            border: 1px #FEEA0F solid;
          }
        }
      }
    }

  }

  &.about-us {
    background-color: #E2E2E273;
    padding-top: 2rem;

    .content {
      > article {
        > ul {
          list-style-type: none;
          padding-inline-start: 0;
  
          > li {
            margin-bottom: 1rem;
          }
        }
      }
    }

  }
}

@media (max-width: $lg-breakpoint) {
  .middle-block {
    min-height: 100vh;
    padding-left: 1rem;
    padding-right: 1rem;

    &.next-era {
      > .content {
        > .buttons {
          display: block;
          
          button {
            display: block;
            margin-bottom: 1rem;
            width: 100%; 
  
            &:first-child {
              margin-right: 0;
            }
  
            &:last-child {
              margin-left: 0;
            }
          }
        }
      }
    }

  }
}

</style>