<template>
  <banner-block jump-cat></banner-block>
  <div class="middle-block google-map">
    <div class="content">
      <h2>會場&交通辦法</h2>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5112.035328775318!2d121.6105374308771!3d25.04239243554148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ab46b3aaaaab%3A0x6ad0b8243ddc70ef!2z5Lit5aSu56CU56m26Zmi5Lq65paH56S-5pyD56eR5a246aSo!5e0!3m2!1szh-TW!2stw!4v1719755960238!5m2!1szh-TW!2stw"
        height="450"
        style="border:0;"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <p>中央研究院 人文社會科學館</p>
      <p>台北市南港區研究院路2段128號</p>
    </div>
  </div>
  <div class="middle-block traffic-1">
    <div class="content">
      <div class="traffic-block">
        <div class="trans-card mrt">
          <div class="icon">
            <img src="../assets/trans-mrt.png" />
          </div>
          <h3>搭捷運至南港展覽館</h3>
          <p>5號出口</p>
        </div>
        <div class="direct-block">
          <span class="direction">▼</span>
        </div>
        <div class="trans-card bus">
          <div class="icon">
            <img src="../assets/trans-bus.png" />
          </div>
          <h3>轉乘公車至中研院站</h3>
          <p>205、212、276、306、306區、64</p>
        </div>
      </div>

      <div class="traffic-block">
        <div class="trans-card mrt">
          <div class="icon">
            <img src="../assets/trans-mrt.png" />
          </div>
          <h3>搭捷運或火車至南港站</h3>
          <p>2號出口</p>
        </div>
        <div class="direct-block">
          <span class="direction">▼</span>
        </div>
        <div class="trans-card bus">
          <div class="icon">
            <img src="../assets/trans-bus.png" />
          </div>
          <h3>轉乘公車至中研院站</h3>
          <p>212直、270</p>
        </div>
      </div>

      <div class="traffic-block self-bus">
        <div class="trans-card bus">
          <div class="icon">
            <img src="../assets/trans-bus.png" />
          </div>
          <h3>南港捷運站1號出口左側搭乘接駁車</h3>
          <p>
            去程08:30一班/08:50一班<br />
            回程17:15(2台車)
          </p>
        </div>
      </div>
      
      <div class="traffic-block ubike">
        <div class="trans-card bike">
          <div class="icon">
            <img src="../assets/trans-bike.png" />
          </div>
          <h3>或搭至南港站、南港展覽館站後騎乘YOUBIKE 2.0至中研院</h3>
          <p>中研院人文社會科學館、中研院綜合體育館、研究院舊莊街口</p>
        </div>
      </div>
      
    </div>
  </div>
  <div class="middle-block traffic-2">
    <div class="content">
      <div class="trans-card walk">
        <div class="icon">
          <img src="../assets/trans-walk.png" />
        </div>
        <p>至中研院站後，再步行一段路至<b>中研院人文社會科學館</b></p>
      </div>
    </div>
  </div>
  <div class="middle-block traffic-text">
    <div class="content">
      <h2>自行搭車前往</h2>
      <p>
        南港捷運站1號出口至右側公車站->270/212/藍25<br />
        南港車站(臺鐵、高鐵)北1或北2出口-> 679/小12（對側）<br />
        南港展覽展覽館站5號出口至公車站<br />
        至中研院(公車站)，再步行至人文社會科學院。
      </p>
      <h2>南港捷運站搭乘接駁車</h2>
      <p>
        去程出發時間(南港捷運站1號出口->中研院社科院前空地):<br />
        回程出發時間(中研院社科院前空地->南港捷運站1號出口): 17:15(2台車)
      </p>
    </div>
  </div>
</template>

<script>
import BannerBlock from '../components/BannerBlock.vue'

export default {
  name: 'MapView',
  components: { BannerBlock }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables.scss';

.middle-block {
  padding: 1rem;

  > .content {
    max-width: 900px;
    margin: auto;
    padding: 1rem 0;

    > h2 {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -3rem;
        background-color: #FEEA0F;
        height: 100%;
        width: 2rem;
      }
    }
  }

  &.google-map {
    > .content {
      > iframe {
        width: 100%;
      }
    }
  }

  &.traffic-1 {
    background-color: #E2E2E2;

    > .content {
      display: grid;
      gap: 0.5rem 1rem;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 2fr 1fr;
      align-items: center;
    }
  }

  &.traffic-text {
    background-color: #E2E2E2;

    > .content {
      > p {
        line-height: 1.75rem;
      }
    }
  }
}

.traffic-block {
  display: flex;
  flex-direction: column;

  &.ubike {
    grid-column: 1 / 4;
    grid-row: 2;
  }
}

.trans-card {
  display: grid;
  grid-template-columns: 6rem 1fr;
  grid-template-rows: 1fr 1fr;

  > .icon {
    grid-area: 1 / 1 / 3;
    width: 5rem;
    height: 5rem;
    padding: 1rem;
    border-radius: 50%;
    background-color: #FEEA0F;

    > img {
      width: 100%;
    }
  }

  > h3 {
    grid-area: 1 / 2;
    align-self: center;
    margin: 0;
  }

  > p {
    grid-area: 2 / 2;
    align-self: center;
    font-size: smaller;
    margin: 0;
  }

  &.mrt {
    > .icon {
      > img {
        transform: translateY(-0.25rem);
      }
    }
  }

  &.bus {
    > .icon {
      > img {
        transform: translateY(0.25rem);
      }
    }
  }

  &.bike {
    width: 100%;

    > .icon {
      > img {
        transform: translateY(0.5rem);
      }
    }
  }

  &.walk {
    grid-template-rows: 5rem;
    width: 100%;

    > .icon {
      padding: 0.75rem;
      text-align: center;

      > img {
        height: 100%;
        width: unset;
      }
    }

    > p {
      grid-area: 1 / 2 / 2;
      font-size: 1rem;
    }
  }
}

.direct-block {

  > .direction {
    display: block;
    width: 5rem;
    text-align: center;
    font-size: 2rem;
  }
}

@media (max-width: $lg-breakpoint) {
  .middle-block {
    &.traffic-1 {
      > .content {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 2fr 1fr 1fr;
      }
    }
  }

  .traffic-block {
    &.self-bus {
      grid-column: 1 / 3;
      grid-row: 2;
    }

    &.ubike {
      grid-column: 1 / 3;
      grid-row: 3;
    }
  }
}

@media (max-width: $sm-breakpoint) {
  .trans-card {
    grid-template-columns: 4.5rem 1fr;
    grid-template-rows: 1fr 1fr;

    > .icon {
      width: 4rem;
      height: 4rem;
      padding: 0.75rem;
      align-self: center;
    }

    > h3 {
      font-size: 1rem;
    }
  }

  .direct-block {

    > .direction {
      width: 4rem;
    }
  }
}

</style>
