<template>
  <index-header></index-header>
  <index-main></index-main>
  <index-footer></index-footer>
</template>

<script>
import IndexHeader from './layouts/IndexHeader.vue'
import IndexMain from './layouts/IndexMain.vue'
import IndexFooter from './layouts/IndexFooter.vue'

export default {
  name: 'App',
  components: {
    IndexHeader,
    IndexMain,
    IndexFooter
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

body {
  width: 100%;
  font-family: 'Microsoft JhengHei', Helvetica, Arial, sans-serif;
  margin: 0;
}
</style>
