<template>
  <div class="profile">
    <span class="thumbnail">
      <img v-if="thumbnailSrc" :src="thumbnailSrc" />
    </span>
    <h4>{{ fullName }}</h4>
    <small v-if="unitTitle">{{ unitTitle }}</small>
  </div>
</template>

<script>
export default {
  name: 'MemberProfile',
  props: {
    thumbnailSrc: {
      type: String,
      default: require('../assets/未命名貓貓.png')
    },
    fullName: {
      type: String,
      default: '',
      required: true
    },
    unitTitle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;

  > .thumbnail {
    height: 5rem;
    width: 5rem;
    background-color: black;
    border-radius: 50%;

    > img {
      width: 100%;
    }
  }

  > h4 {
    margin: 0.5rem 0;
    text-transform: uppercase;
  }
}

</style>
