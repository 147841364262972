<template>
  <div class="banner-block">
    <img src="../assets/signup-banner.jpg" />
  </div>
  <div class="cat-background cat-background-1">
    <img src="../assets/cat-background-1.png" />
  </div>
  <div class="cat-background cat-background-2">
    <img src="../assets/cat-background-2.png" />
  </div>
  <div class="article-block">
    <div class="content">
      <div class="button-block">
        <button @click="onClickSignup" title="按下之後將會開啟新視窗到kktix報名頁面">活動報名▶</button>
      </div>
      <article>
        <h2>找回初心向前推進</h2>
        <p>
          你是否還記得年幼時，總是充滿好奇心的自己呢?
          隨著時間推移，我們都習慣了既定的人情世故，不再對生命保持熱情…但越是如此，我們就更需要有所行動找回初心!<br />
          萬事起頭難! 但是沒有起頭，又怎麼怎麼會有成果。
          「好奇心」 是一切科學發展的必要動力，如今身在量子電腦即將爆發革命的時代。
          我們「Qracon」團隊鼓勵著任何想了解量子電腦的人，只要對量子理論有興趣，就馬上來投入量子領域吧！
          想像力就是你的超能力，好奇心便是你的原動力!
        </p>
        <h3><mark>好奇心能夠殺死一隻貓，也能<del>救活薛丁格的貓</del>成就不一樣的自己!</mark></h3>
      </article>
      <article>
        <h2>在 0 跟 1 之間的量子世代</h2>
        <p>
          在過去，放眼於國內外，少有一場以國高中、大學生為主體的量子大型交流活動而今年，台灣將迎來第二場專屬於普通學生量子年會。
          從 0 邁向 1 的成長，也預示著未來量子人才的積極投入與培育，讓社會更認識量子，不再只是聽過量子，而是了解量子電腦！<br />
          我們也期許藉由此次的年會活動，能夠號召與聯合國外各地學生量子自治組織能跟上台灣的腳步，讓量子科技領域的基礎人才，得到爆炸式的成長！
        </p>
        <h3><mark>介於 0 跟 1 之間的力量，<br /><br />即便微小，也充滿著無限的可能性 !</mark></h3>
      </article>
    </div>

  </div>
</template>

<script>
export default {
  name: 'SignupView',
  methods: {
    onClickSignup () {
      window.open('https://qracon2024y2nd.kktix.cc/events/9df2b2ca-copy-1')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables.scss';

.banner-block {
  position: relative;
  z-index: 1;
  background-image: url('../assets/signup-banner-lighter.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  padding-left: 15rem;
  padding-right: 15rem;

  > img {
    width: 100%;
  }
}

.cat-background {
  position: fixed;
  z-index: -1;

  &.cat-background-1 {
    right: 0;
    top: 25%;
    width: 25%;
  }

  &.cat-background-2 {
    left: 0;
    top: 50%;
    width: 25%;
  }

  > img {
    width: 100%;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}

.article-block {
  min-height: 100vh;
  padding: 2rem 0;

  > .content {
    max-width: 720px;
    margin: auto;
    
    > .button-block {
      margin-bottom: 5rem;
  
      button {
        width: 50%;
        background-color: #FEEA0F;
        font-weight: bold;
        font-size: 2.5rem;
        padding: 1rem;
        box-shadow: 5px 5px black;
        cursor: pointer;
  
        &:hover {
          background-color: black;
          color: #FEEA0F;
          box-shadow: 5px 5px #FEEA0F;
          border: 1px #FEEA0F solid;
        }
      }
    }
  
    > article {
  
      > h2 {
        position: relative;
  
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -3rem;
          background-color: #FEEA0F;
          height: 100%;
          width: 2rem;
        }
      }
  
      > p {
        line-height: 2.5rem;
      }
  
      &:not(:last-child) {
        margin-bottom: 5rem;
      }
  
      > h3 {
        font-size: 1.5rem;
      }
    }
  }

}

@media (max-width: $lg-breakpoint) {
  .banner-block {
    padding-left: 0;
    padding-right: 0;
  }

  .article-block {
    padding-left: 1rem;
    padding-right: 1rem;

    > .content {
      > .button-block {
        margin-bottom: 2.5rem;
  
        button {
          width: 100%;
        }
      }
    }

  }
}

</style>
