<template>
  <div class="svg-icon" v-html="posts"></div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    src: {
      type: String,
      default: ''
    }
  },
	data () {
		return {
			posts: ''
		}
	},
  mounted () {
		this.getSvg()
	},
	methods: {
		async getSvg () {
			const { data } = await axios.get(this.src)
      this.posts = data
		}
	}
}
</script>

<style lang="scss" scoped>
.svg-icon {
	height: 24px;
	width: 24px;
}
</style>
