<template>
  <footer>
    <div class="content">
      <h4>QRACON<span class="sub">第二屆學生量子電腦年會</span></h4>
      <ul class="outer-links">
        <li>
          <a href="https://www.facebook.com/sqcs819/" target="_blank">
            <img src="../assets/fb.png" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/entangletech.2023/" target="_blank">
            <img src="../assets/ig.png" />
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/@EntangleTech" target="_blank">
            <img src="../assets/yt.png" />
          </a>
        </li>
        <li>
          <a href="https://www.threads.net/@entangletech.2023?xmt=AQGzZ3UUmO-_BjIt5WpUs6wBu7aNls8b7BaUlG-FhxtxaG4" target="_blank">
            <img src="../assets/threads.png" />
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import '../scss/variables.scss';

footer {
  background-color: black;
  color: white;
  padding: 1rem 1rem 5rem;

  > .content {
    display: flex;
    justify-content: space-between;
    max-width: 720px;
    margin: auto;
  }
}

h4 {
  font-size: 1.6rem;
  margin: 0;

  > .sub {
    font-size: 0.8rem;
  }
}

.outer-links {
  padding-inline-start: 0;
  display: flex;
  align-items: flex-end;
  margin-block-start: 0;
  margin-block-end: 0;

  > li {
    display: block;
    flex: auto;
    text-align: center;

    > a {
      display: inline-block;
      width: 2rem;
      height: 2rem;

      > img {
        max-width: 2rem;
        max-height: 2rem;
      }
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

@media (max-width: $sm-breakpoint) {
  footer {
    > .content {
      display: block;

      > h4 {
        margin-bottom: 1rem;
        text-align: center;
      }
    }
  }
}

</style>