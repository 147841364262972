<template>
  <div class="banner-block">
    <img class="normal-banner" src="../assets/banner.jpg" />
    <img class="mobile-banner" src="../assets/banner-mobile.jpg">
    <template v-if="jumpCat">
      <img class="date-image" src="../assets/index-activity-date.png" />
      <img class="jumping-cat jumping-cat1" src="../assets/jumping-cat.png" />
      <img class="jumping-cat jumping-cat2" src="../assets/jumping-cat.png" />
      <img class="belt normal-belt" src="../assets/index-belt.png" />
      <img class="belt mobile-belt" src="../assets/index-belt-mobile.jpg" />
    </template>
  </div>
</template>

<script>
export default {
  name: 'BannerBlock',
  props: {
    jumpCat: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables.scss';

.banner-block {
  position: relative;
  z-index: 1;
  width: 100%;
  overflow-x: hidden;

  > img {

    &.normal-banner {
      width: 100%;
      display: block;
    }

    &.mobile-banner {
      width: 100%;
      display: none;
    }

    &.date-image {
      position: absolute;
      top: 1rem;
      right: 2rem;
      width: 20rem;
    }

    &.belt {
      transform: translateY(-25%);

      &.normal-belt {
        width: 100%;
        display: block;
      }
  
      &.mobile-belt {
        width: 100%;
        display: none;
      }
    }

  }
}

.jumping-cat {
  position: absolute;
  top: 50%;
  width: 50%;
  transform: translateY(-50%);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &.jumping-cat1 {
    left: 50%;
    animation: jump-1 3s infinite;
  }

  &.jumping-cat2 {
    left: -50%;
    animation: jump-2 3s infinite;
  }

  @keyframes jump-1 {
    from {
      left: 50%;
    }
    to {
      left: 150%;
    }
  }

  @keyframes jump-2 {
    from {
      left: -50%;
    }
    to {
      left: 50%;
    }
  }
}

@media (max-width: $sm-breakpoint) {
  .banner-block {
    > img {
      &.normal-banner {
        display: none;
      }

      &.mobile-banner {
        display: block;
      }

      &.date-image {
        right: 1rem;
        width: 10rem;
      }

      &.normal-belt {
        display: none;
      }

      &.mobile-belt {
        display: block;
      }
    }
  }

  .jumping-cat {
    top: 70%;
    width: 110%;
    transform: translateY(-50%);

    &.jumping-cat1 {
      left: 0;
      animation: jump-1 3s infinite;
    }

    &.jumping-cat2 {
      left: -100%;
      animation: jump-2 3s infinite;
    }

    @keyframes jump-1 {
      from {
        left: 0%;
      }
      to {
        left: 100%;
      }
    }

    @keyframes jump-2 {
      from {
        left: -100%;
      }
      to {
        left: 0;
      }
    }
  }
}

</style>