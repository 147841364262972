<template>
  <main>
    <router-view></router-view>
  </main>
</template>

<style lang="scss" scoped>
@import '../scss/variables.scss';

main {
  padding-top: 3rem;
}

</style>
