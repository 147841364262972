<template>
  <header>
    <div class="logo-block">
      <router-link to="/" custom v-slot="{ navigate }">
        <h1 @click="navigate" title="首頁">QRACON</h1>
      </router-link>
      <button
        type="button" class="menu-button"
        @click="onClickMenuBtn"
      >
        <svg-icon :src="require('@/assets/hamburger.svg')"></svg-icon>
      </button>
    </div>
    <nav ref="nav-menu">
      <ul>
        <li><router-link to="/" title="年會活動">年會活動</router-link></li>
        <li><router-link to="/agenda" title="議程表">議程表</router-link></li>
        <li><router-link to="/map" title="會場地圖">會場地圖</router-link></li>
        <li><router-link to="/signup" title="報名資訊">報名資訊</router-link></li>
        <li><router-link to="/notice" title="注意事項">注意事項</router-link></li>
        <li><router-link to="/partners" title="合作夥伴">合作夥伴</router-link></li>
      </ul>
    </nav>
  </header>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
export default {
  name: 'IndexHeader',
  components: { SvgIcon },
  methods: {
    onClickMenuBtn () {
      const navMenu = this.$refs['nav-menu']
      navMenu.classList.toggle('open')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables.scss';

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  background-color: black;
  height: 3rem;
}

.logo-block {
  h1 {
    cursor: pointer;
    font-family: 'Noto Sans', Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 800;
    margin: 0;
    color: white;
  }
  
  .menu-button {
    display: none;
    background-color: transparent;
    border: none;
    width: 100%;
    cursor: pointer;
    padding: 0.5rem 0;
    
    .svg-icon {
      margin: auto;
      height: 32px;
      width: 32px;
    }
  }
}


nav {
  & > ul {
    display: block;
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;

    & > li {
      display: inline-block;
      transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;

      & > a {
        display: block;
        font-family: 'Source Sans Pro', 'Microsoft JhengHei', sans-serif;
        font-weight: 700;
        text-decoration: none;
        color: white;
        padding: 0.75rem 1rem;

        &.router-link-active {
          color: #FEEA0F;
        }
      }

      &:hover > a {
        color: #FEEA0F;
      }
    }
  }
}

@media (max-width: $lg-breakpoint) {
  header {
    display: block;
    height: unset;
    min-height: 3rem;
    padding: 0;
  }

  .logo-block {
    display: flex;
    align-items: center;
    
    h1 {
      flex: auto;
      margin-left: 48px;
      text-align: center;
    }
  
    .menu-button {
      display: inline-block;
      width: 40px;
    }
  }


  nav {
    height: 0;
    overflow: hidden;
    transition: height 1s;

    & > ul {
      & > li {
        display: block;

        & > a {
          text-align: center;
        }
      }
    }

    &.open {
      height: 275px;
    }
  }
}

</style>