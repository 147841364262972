<template>
  <banner-block></banner-block>
  <div class="middle-block">
    <div class="content">
      <h2>主辦單位</h2>
      <img class="entangle-tech-logo" src="../assets/EntangleTech-logo.jpg" />
      <article class="entangle-article">
        <h3>EntangleTech</h3>
        <p>
          我們是臺灣第一個以推廣與教學量子計算為主業的新創團隊,過去以 SQCS
          (學生量子電腦協會)為名成立,今年正式更名為 前瞻量子教育平台。<br />
          我們的創立是為打破既有現狀,營造適合臺灣乃至華語地區的友善學習環
          境,幫助臺灣莘莘學子與廣大初學者們學習量子計算,推動社會普及運動,
          協助大眾了解其對當代社會所具備的深遠影響力。
        </p>
      </article>
      <h2>指導單位</h2>
      <div class="logos-row direct-by">
        <div class="logo-block">
          <div class="img-block">
            <img src="../assets/RCAS.png" />
          </div>
          <p>中央研究院<br />量子光電專題中心</p>
        </div>
        <div class="logo-block">
          <div class="img-block">
            <img src="../assets/tsmc-logo.jpg" />
          </div>
          <p>台積電文教基金會</p>
        </div>
        <div class="logo-block">
          <div class="img-block">
            <img src="../assets/nstc-logo.jpg" />
          </div>
          <p>國家科學及技術委員會</p>
        </div>
      </div>
      <h2>協辦單位</h2>
      <div class="logos-row assist-1">
        <div class="logo-block">
          <div class="img-block">
            <img src="../assets/台灣大學_IBM量子電腦中心.png" />
          </div>
          <p>國立台灣大學<br />IBM量子電腦中心</p>
        </div>
        <div class="logo-block">
          <div class="img-block">
            <img src="../assets/成大前沿量子科技研究中心 - QFort.png" />
          </div>
          <p>國立成功大學<br />前沿量子科技研究中心</p>
        </div>
        <div class="logo-block">
          <div class="img-block">
            <img src="../assets/國立政治大學應用物理研究所.png" />
          </div>
          <p>國立政治大學<br />應用物理研究所</p>
        </div>
      </div>
      <div class="logos-row assist-2">
        <div class="logo-block">
          <div class="img-block">
            <img src="../assets/中原大學量子資訊中心.png" />
          </div>
          <p>中原大學量子資訊中心</p>
        </div>
        <div class="logo-block">
          <div class="img-block">
            <img src="../assets/臺灣量子電腦兼資訊科技協會.png" />
          </div>
          <p>臺灣量子電腦兼資訊科技協會</p>
        </div>
        <div class="logo-block">
          <div class="img-block">
            <img src="../assets/國立台灣大學量子計算研究社.webp" />
          </div>
          <p>國立台灣大學<br />量子計算研究社</p>
        </div>
      </div>
      <div class="logos-row assist-3">
        <div class="logo-block">
          <div class="img-block">
            <img src="../assets/IBM量子計算國際倡導者.png" />
          </div>
          <p>IBM QISKIT ADVOCATES</p>
        </div>
        <div class="logo-block">
          <div class="img-block">
            <img src="../assets/中原大學_智慧運算與大數據學系.png" />
          </div>
          <p>中原大學</p>
        </div>
      </div>
      <h2>媒體單位</h2>
      <div class="logos-row media">
        <div class="logo-block">
          <div class="img-block">
            <img src="../assets/北極星工作室_人工智慧與半導體暨量子計算機教學研習社.jpg" />
          </div>
          <p>北極星工作室<br />人工智慧與半導體暨量子計算機教學研習社</p>
        </div>
        <div class="logo-block">
          <div class="img-block">
            <img src="../assets/資料科學 資料科學家的工作日常 ❙ 維元.png" />
          </div>
          <p>資料科學<br />資料科學家的工作日常 維元</p>
        </div>
      </div>
      <h2>感謝贊助</h2>
      <div class="logos-row sponsor">
        <div class="logo-block">
          <div class="img-block">
            <img src="../assets/O.ZIP 迷你甜點.jpg" />
          </div>
          <p>O.ZIP 迷你甜點</p>
        </div>
        <div class="logo-block">
          <p>美安文教基金會</p>
        </div>
        <div class="logo-block">
          <div class="img-block">
            <img src="../assets/Wannian team.png" />
          </div>
          <p>Wannian team</p>
        </div>
      </div>
      <h2>QRACON年會成員</h2>
      <div class="profiles-block">
        <member-profile
          v-for="profile in mainProfiles" :key="profile.fullName"
          v-bind="profile"
        ></member-profile>
      </div>
      <h3>行銷組</h3>
      <div class="profiles-block">
        <member-profile
          v-for="profile in marketingProfiles" :key="profile.fullName"
          v-bind="profile"
        ></member-profile>
      </div>
      <h3>議程組</h3>
      <div class="profiles-block">
        <member-profile
          v-for="profile in agendaProfiles" :key="profile.fullName"
          v-bind="profile"
        ></member-profile>
      </div>
      <h3>活動組</h3>
      <div class="profiles-block">
        <member-profile
          v-for="profile in activityProfiles" :key="profile.fullName"
          v-bind="profile"
        ></member-profile>
      </div>
    </div>
  </div>
</template>

<script>
import BannerBlock from '../components/BannerBlock.vue'
import MemberProfile from '../components/MemberProfile.vue'

export default {
  name: 'PartnersView',
  components: { BannerBlock, MemberProfile },
  data () {
    return {
      mainProfiles: [
        {
          fullName: '葉羽修', unitTitle: '總召(行銷總監)',
          thumbnailSrc: require('../assets/葉羽修.png')
        },
        {
          fullName: '李翊愷', unitTitle: '副召(議程總監)',
          thumbnailSrc: require('../assets/李翊愷.png')
        },
        {
          fullName: '廖文謙', unitTitle: '(總監)',
          thumbnailSrc: require('../assets/廖文謙.png')
        },
        {
          fullName: '曾琦', unitTitle: '文書',
          thumbnailSrc: require('../assets/曾琦.png')
        },
        {
          fullName: '王令瑄', unitTitle: '財務',
          thumbnailSrc: require('../assets/王令瑄.png')
        },
        {
          fullName: '林祐睿', unitTitle: '財務',
          thumbnailSrc: require('../assets/林祐睿.png')
        }
      ],
      marketingProfiles: [
        {
          fullName: '羅紫瑜', unitTitle: '組長',
          thumbnailSrc: require('../assets/羅紫瑜.png')
        },
        {
          fullName: '洪宇彥', unitTitle: '副組長',
          thumbnailSrc: require('../assets/洪宇彥.png')
        },
        {
          fullName: '張庭瑄',
          thumbnailSrc: require('../assets/張庭瑄.png')
        },
        {
          fullName: '盧郁禮',
          thumbnailSrc: require('../assets/盧郁禮.png')
        },
        { fullName: '陳冠廷', unitTitle: '(攝影部)' },
        {
          fullName: '陳韞', unitTitle: '(設計部)',
          thumbnailSrc: require('../assets/陳韞.png')
        },
        {
          fullName: '蔡烜廷',
          thumbnailSrc: require('../assets/蔡烜廷.png')
        },
        { fullName: '王廷文' },
        { fullName: '汪翰慈' },
        { fullName: '傑哥' }
      ],
      agendaProfiles: [
        {
          fullName: '黃承瑋', unitTitle: '組長',
          thumbnailSrc: require('../assets/黃承瑋.png')
        },
        {
          fullName: '趙嫺', unitTitle: '副組長',
          thumbnailSrc: require('../assets/趙嫺.png')
        },
        { fullName: '翁宸瑋' },
        { fullName: '陳子秝' },
        { fullName: '劉珍君' },
        {
          fullName: '張德生',
          thumbnailSrc: require('../assets/張德生.png')
        },
        {
          fullName: '莊懷德',
          thumbnailSrc: require('../assets/莊懷德.png')
        },
        {
          fullName: '歐長諺',
          thumbnailSrc: require('../assets/歐長諺.png')
        },
        {
          fullName: '陳姸榕',
          thumbnailSrc: require('../assets/陳姸榕.png')
        },
        { fullName: '林百洲' }
      ],
      activityProfiles: [
        {
          fullName: '林佳笙', unitTitle: '組長',
          thumbnailSrc: require('../assets/林佳笙.png')
        },
        {
          fullName: '黃筱喬',
          thumbnailSrc: require('../assets/黃筱喬.png')
        },
        {
          fullName: '黃奕愷',
          thumbnailSrc: require('../assets/黃奕愷.png')
        },
        {
          fullName: '陳喬瑄',
          thumbnailSrc: require('../assets/陳喬瑄.png')
        },
        {
          fullName: '顏佑丞',
          thumbnailSrc: require('../assets/顏佑丞.png')
        },
        {
          fullName: '杜秉錡',
          thumbnailSrc: require('../assets/杜秉錡.png')
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables.scss';

.middle-block {
  padding: 1rem;

  > .content {
    max-width: 720px;
    margin: auto;
    padding: 1rem 0;

    > h2 {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -3rem;
        background-color: #FEEA0F;
        height: 100%;
        width: 2rem;
      }
    }

    > h3 {
      margin-top: 0;
    }
  }
}

.entangle-tech-logo {
  width: 100%;
}

.entangle-article {
  > h3 {
    text-transform: uppercase;
    font-size: 1.25rem;
  }

  > p {
    line-height: 2rem;
  }
}

.logos-row {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .logo-block {
    width: 30%;

    > .img-block {
      height: 90px;
      
      > img {
        display: block;
        margin: auto;
      }
    }

    > p {
      text-align: center;
      font-weight: bold;
    }
  }

  &.direct-by {
    .logo-block {
      &:nth-child(1) {
        > .img-block {
          > img {
            height: 100%;
          }
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        > .img-block {
          padding-top: 45px;

          > img {
            width: 100%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  &.assist-1 {
    .logo-block {
      > .img-block {
        padding-top: 45px;

        > img {
          width: 100%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &.assist-2 {
    .logo-block {
      &:nth-child(1) {
        > .img-block {
          padding-top: 45px;
          > img {
            width: 40%;
            transform: translateY(-50%);
          }
        }
      }

      &:nth-child(2) {
        > .img-block {
          padding-top: 45px;
          > img {
            width: 100%;
            transform: translateY(-50%);
          }
        }
      }

      &:nth-child(3) {
        > .img-block {
          > img {
            height: 100%;
          }
        }
      }
    }
  }

  &.assist-3 {
    .logo-block {
      &:nth-child(1) {
        > .img-block {
          padding-top: 45px;
          > img {
            width: 75px;
            transform: translateY(-50%);
          }
        }
      }
  
      &:nth-child(2) {
        > .img-block {
          padding-top: 45px;
          > img {
            width: 100%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  &.media {
    .logo-block {
      &:nth-child(1) {
        width: 60%;
        > .img-block {
          > img {
            height: 100%;
          }
        }
      }

      &:nth-child(2) {
        width: 30%;
        > .img-block {
          > img {
            height: 100%;
          }
        }
      }
    }
  }

  &.sponsor {
    .logo-block {
      &:nth-child(1) {
        > .img-block {
          > img {
            height: 100%;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
          }
        }
      }

      &:nth-child(2) {
        > p {
          line-height: 90px;
          box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
        }
      }

      &:nth-child(3) {
        > .img-block {
          > img {
            height: 100%;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
}

.profiles-block {
  display: flex;
  flex-wrap: wrap;

  > .profile {
    width: 20%;
    margin-bottom: 1rem;
  }
}

@media (max-width: $sm-breakpoint) {
  .logos-row {
    .logo-block {
      width: 100%;
      margin-bottom: 1.5rem;
      
      > .img-block {
        height: unset;
      }
    }

    &.direct-by {
      .logo-block {
        &:nth-child(1) {
          > .img-block {
            > img {
              height: 200px;
            }
          }
        }

        &:nth-child(2),
        &:nth-child(3) {
          > .img-block {
            padding-top: 0;

            > img {
              transform: translateY(0);
            }
          }
        }
      }
    }

    &.assist-1 {
      .logo-block {
        > .img-block {
          padding-top: 0;

          > img {
            transform: translateY(0);
          }
        }
      }
    }

    &.assist-2 {
      .logo-block {
        &:nth-child(1) {
          > .img-block {
            padding-top: 0;
            > img {
              width: 60%;
              transform: translateY(0);
            }
          }
        }

        &:nth-child(2) {
          > .img-block {
            padding-top: 0;
            > img {
              width: 100%;
              transform: translateY(0);
            }
          }
        }

        &:nth-child(3) {
          > .img-block {
            > img {
              height: 200px;
            }
          }
        }
      }
    }

    &.assist-3 {
      .logo-block {
        &:nth-child(1) {
          > .img-block {
            padding-top: 0;
            > img {
              width: 150px;
              transform: translateY(0);
            }
          }
        }
    
        &:nth-child(2) {
          > .img-block {
            padding-top: 0;
            > img {
              
              transform: translateY(0);
            }
          }
        }
      }
    }

    &.media {
      .logo-block {
        &:nth-child(1) {
          width: 100%;
          > .img-block {
            > img {
              width: 100%;
            }
          }
        }

        &:nth-child(2) {
          width: 100%;
        }
      }
    }

    &.sponsor {
      .logo-block {
        &:nth-child(1) {
          > .img-block {
            > img {
              height: 200px;
            }
          }
        }

        &:nth-child(2) {
          > p {
            font-size: 2rem;
            line-height: 90px;
          }
        }

        &:nth-child(3) {
          > .img-block {
            > img {
              height: 200px;
            }
          }
        }
      }
    }
  }

  .profiles-block {
    > .profile {
      width: 33%;
    }
  }
}

</style>