<template>
  <banner-block></banner-block>
  <div class="content-block">
    <div class="content">
      <h2>活動議程表</h2>
      <div class="schedule-container">
        <div
          v-for="(cell, index) in scheduleCells" :key="index"
          class="cell"
          :class="cell.class"
          :style="toGridCellStyle(cell.column, cell.row)"
        >
          <template v-if="cell.type === 'image'">
            <img :src="cell.src" />
          </template>
          <template v-else-if="cell.type === 'icon'">
            <img :src="cell.src" />
          </template>
          <template v-else-if="cell.type === 'time'">
            <p>
              <span>{{ cell.startTime }}</span>
              <span>-</span>
              <span>{{ cell.endTime }}</span>
            </p>
          </template>
          <template v-else-if="cell.type === 'header'">
            <p>
              <span>{{ cell.code }}</span>
              <span>{{ cell.text }}</span>
            </p>
          </template>
          <template v-else>
            <p>
              <template v-if="cell.text">
                {{ cell.text }}
              </template>
              <b v-if="cell.boldText">{{ cell.boldText }}</b>
              <template v-if="cell.nextLineText">
                <br />
                {{ cell.nextLineText }}
              </template>
            </p>
          </template>
        </div>
        <div class="cat center-top">
          <img src="../assets/position-cat-1.png" />
        </div>
      </div>
      <div class="position-container">
        <div
          v-for="(cell, index) in positionCells" :key="index"
          class="cell"
          :class="cell.class"
          :style="toGridCellStyle(cell.column, cell.row)"
        >
          <template v-if="cell.type === 'image'">
            <img :src="cell.src" />
          </template>
          <template v-else-if="cell.type === 'icon'">
            <img :src="cell.src" />
          </template>
          <template v-else-if="cell.type === 'time'">
            <p>
              <span>{{ cell.startTime }}</span>
              <span>-</span>
              <span>{{ cell.endTime }}</span>
            </p>
          </template>
          <template v-else-if="cell.type === 'header'">
            <p>
              <span>{{ cell.code }}</span>
              <span>{{ cell.text }}</span>
            </p>
          </template>
          <template v-else>
            <p>
              {{ cell.text }}
              <template v-if="cell.nextLineText">
                <br />
                {{ cell.nextLineText }}
              </template>
            </p>
          </template>
        </div>
        <div class="cat right-bottom">
          <img src="../assets/position-cat-2.png" />
        </div>
        <div class="cat left-bottom">
          <img src="../assets/position-cat-3.png" />
        </div>
      </div>
      <h2>QRACON年會主打內容介紹</h2>
      <div class="article-block article-block-with-image">
        <img src="../assets/agenda-image1.jpg" />
        <article>
          <h3>專家演講</h3>
          <p>
            邀請多位量子科技領域的專家進行50分鐘的演講，針對自己的領域介紹，
            並在每場演講後設置問答環節，讓參與者有機會直接向專家提問，深入探討相關話題。
          </p>
        </article>
      </div>
      <div class="article-block">
        <article>
          <h4>鐘楷閔</h4>
          <p>
            主題：量子計算的潛力與極限 -- 如何正確想像量子電腦的能力<br />
            摘要：量子電腦作為新世代的電腦，我們該如何想像他們的計算能力？
            本場演講從將複雜度理論配合量子電腦的應用來展示量子電腦的計算能力。
            聽眾能由此演講來對量子電腦有正確的想像。
          </p>
        </article>
      </div>
      <div class="article-block">
        <article>
          <h4>張慶瑞</h4>
          <p>
            主題：當人工智慧遇見量子電腦<br />
            摘要：量子人工智慧是研究人工智慧與量子計算的融合：一方面量子計算可以加速解決一些複雜的人工智慧問題；
            另一方面，人工智慧裡面的一些方法與技術也可以用來協助解決量子領域裡的問題。
            量子計算和人工智慧的結合，在ChatGPT出現後更受到廣泛關注。
            人工智慧與量子計算的技術疊加，將是顛覆與顛覆的乘積，會帶領世界走向怎樣未來？
            技術性的失業問題可能比工業革命後更嚴重。
            本報告將重點介紹量子科技的發展，量子人工智慧領域的部分前沿進展情況以及世界未來的可能變化。
          </p>
        </article>
      </div>
      <div class="article-block">
        <article>
          <h4>鄭原忠</h4>
          <p>
            主題：量子計算與計算化學 — 淺談量子革命的現實與挑戰<br />
            摘要：量子計算的實現有望對計算化學問題的求解帶來革命性的改變，其結果將對材料設計、藥物發現、
            大氣化學模擬等等眾多領域有深遠的影響。在這次演講，我將會說明在處理計算化學問題時，
            量子計算有什麼樣的優勢，以及這些優勢為何可以協助找到更好的材料以及藥物，
            此外，我也會利用最新文獻的探討分析來勾勒目前量子計算的最新趨勢與進展，
            並討論實現量子計算在計算化學上的應用時面臨的幾項重大挑戰。
            希望能透過這個演講，讓與會者可以更清晰的了解量子計算的可能應用，
            並辨識實現這些應用的困難點，以鼓勵大家一起投入這個領域來實現未來量子計算的創新發展。
          </p>
        </article>
      </div>
      <div class="article-block">
        <article>
          <h4>林晏詳</h4>
          <p>
            主題：淺談量子電腦與超導量子位元<br />
            摘要：量子電腦作為下一代計算技術，正在引領一場未來可能發生的革命性變革。
            這場演講中，我將介紹量子電腦的基本概念，特別是超導量子位元的運作原理與其在量子計算中的應用。
            並且討論量子電腦的核心組成單位—量子位元（Qubit）。
            與傳統計算中的位元不同，量子位元利用量子疊加和量子糾纏特性來實現更強大的計算能力。
            接著，我們會探討量子計算的基本原理，並簡要介紹一些重要的量子演算法。
            這場演講的重點將放在超導量子位元上。
            我會解釋什麼是超導現象及其基本特性，並深入探討超導量子位元的工作原理。
            此外，我還會介紹幾種類型的超導量子位元，如Transmon、Fluxonium。
            儘管超導量子位元展示了巨大的潛力，但其發展過程中仍面臨著諸多挑戰。
            其中包括去相干與能量耗散問題，以及製造與操作技術上的困難。
            這些挑戰不僅影響了量子位元的性能，也對其實際應用帶來了阻礙。
            最後，我將探討量子電腦的未來前景。
            當前，量子電腦已展現出初步應用的可能性。
            未來，隨著技術的不斷突破，量子電腦有可能實現商業化，並對科學技術和社會產生深遠影響。
            希望通過這次演講，能夠加深大家對量子電腦與超導量子位元的理解，並啟發更多對這一領域的興趣和研究。
          </p>
        </article>
      </div>
      <div class="article-block">
        <article>
          <h4>劉宸銉</h4>
          <p>
            主題：用量子機器學習訓練古典神經網路<br />
            摘要：量子機器學習是近年來量子計算領域的熱門研究議題。
            目前常用的量子計算方法面臨一些挑戰，例如資料編碼成量子態的過程，以及訓練後推論階段需要使用數量稀少的量子電腦。
            為了解決這些問題，我們提出了量子訓練方法（Quantum-Train）。
            這種方法不僅不需要資料編碼成量子態，訓練完成後的模型也可以在不使用量子電腦的情況下進行推論。
            相比於傳統的神經網路，我們的方法可以將訓練階段所需的參數降至Polylog等級。
            作為一個新的量子機器學習框架，量子訓練展示了巨大的發展潛力。
          </p>
        </article>
      </div>
      <div class="article-block">
        <article>
          <h3>量子進程展</h3>
          <p>
            以量子科技的歷史發展為核心，
            從古典物理的局限到量子力學的突破，一步步揭示量子世界的奧秘。
            展覽包括多個互動展區，每一區都有其獨特主題，如量子計算、量子密碼學及量子化學等，
            不僅展示技術的演進和科學家們的貢獻，還配有豐富的教學資料和線上教材，
            旨在提高學生們的學習興趣和科技素養。
            通過這次展覽，學生們將能更好地理解量子技術如何塑造未來世界，並啟發他們追求科學創新的熱情。
          </p>
        </article>
      </div>
      <div class="article-block article-block-with-image">
        <img src="../assets/agenda-image2.jpg" />
        <article>
          <h3>量子研究海報競賽</h3>
          <p>
            由學生組隊參加海報競賽，競賽期間共同研究量子科技之主題，旨在鼓勵學
            生培養自學習慣。競賽會由議程組同仁進行正確性的檢查，然後展出於會場共參展人進行投
            票，票數最高者可獲得最高獎品(<a href="https://drive.google.com/file/d/10sOZFNK4s4bufwRNWeGimwFFT-CGZ6_Z/view?usp=sharing" target="_blank">簡章連結</a>)
          </p>
        </article>
      </div>
      <div class="article-block">
        <article>
          <h3>桌長計畫</h3>
          <p>
            邀請數位經驗豐富的研究生、博士生到現場與與會者進行面對面交流討論，
            讓與會者可以針對自己感興趣的議題進行提問和探討。
            並且同時設立白板區，讓討論後的結果可以在白板上進行分享，抑或隨意寫下對年會參加的感受。
          </p>
        </article>
      </div>
      <img class="full-width-image" src="../assets/agenda-image3.jpg" />
      <div class="article-block">
        <article>
          <h3>量子電玩競賽區</h3>
          <p>
            將藉由競賽的形式，活動開始時，主辦方將介紹量子遊戲的基本規則和背後的量子理論概念。
            參與者將被分組進行一系列的量子遊戲挑戰，每個挑戰都旨在測試他們對量子理論概念的理解和應用能力。
          </p>
        </article>
      </div>
      <div class="article-block">
        <article>
          <h3>量子學術成果展區</h3>
          <p>
            與國家量子研究中心合作，透過展出臺灣量子中心的研究成果，讓與會者了解量子未來出路跟研究方向 。
          </p>
        </article>
      </div>
      <div class="article-block">
        <article>
          <h3>量子基礎工作坊(名額有限!請在入場時詢問報名)</h3>
          <p>
            由前瞻量子教育平台專任講師出擊 ! 向您介紹最新、最淺顯易懂的量子運算學習教材
          </p>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import BannerBlock from '../components/BannerBlock.vue'

export default {
  name: 'AgendaView',
  components: { BannerBlock },
  data () {
    return {
      scheduleCells: [
        { type: 'icon', src: require('@/assets/schedule-container-icon.png'), class: 'icon', column: 1, row: 1 },
        { type: 'header', code: '3A', text: '報到處區', class: 'header', column: 2, row: 1 },
        { type: 'header', code: '3B', text: '講廳', class: 'header', column: 3, row: 1 },
        { type: 'header', code: '3C', text: '講廳', class: 'header', column: 4, row: 1 },
        { type: 'header', code: '3D', text: '區域', class: 'header', column: 5, row: 1 },
        { type: 'header', code: '3E', text: '區域', class: 'header', column: 6, row: 1 },

        { type: 'time', startTime: '8:30', endTime: '9:00', class: 'time', column: 1, row: 2 },
        { type: 'time', startTime: '9:10', endTime: '10:00', class: 'time', column: 1, row: 3 },
        { type: 'time', startTime: '10:10', endTime: '11:00', class: 'time', column: 1, row: 4 },
        { type: 'time', startTime: '11:10', endTime: '12:00', class: 'time', column: 1, row: 5 },
        { type: 'time', startTime: '12:00', endTime: '13:20', class: 'time', column: 1, row: 6 },
        { type: 'time', startTime: '13:30', endTime: '14:20', class: 'time', column: 1, row: 7 },
        { type: 'time', startTime: '14:30', endTime: '15:20', class: 'time', column: 1, row: 8 },
        { type: 'time', startTime: '15:30', endTime: '16:20', class: 'time', column: 1, row: 9 },
        { type: 'time', startTime: '16:30', endTime: '17:00', class: 'time', column: 1, row: 10 },

        { text: '開始報到', class: 'subject', column: 2, row: 2 },
        { text: '用心為您服務', class: 'subject', column: 2, row: '3 / 10' },
        { text: '快樂回家', class: 'subject', column: 2, row: 10 },

        { text: '開幕與組織簡介', class: 'subject', column: 3, row: 3 },
        { boldText: '鍾楷閔', nextLineText: '量子計算的潛力與極限', class: 'subject', column: 3, row: 4 },
        { boldText: '張慶瑞', nextLineText: '當人工智慧遇見量子電腦？', class: 'subject', column: 3, row: 5 },
        { text: 'break', class: 'subject', column: 3, row: 6 },
        { boldText: '鄭原忠', nextLineText: '量子化學', class: 'subject', column: 3, row: 7 },
        { boldText: '林晏詳', nextLineText: '淺談量子電腦與超導量子位元', class: 'subject', column: 3, row: 8 },
        { boldText: '劉宸銉', nextLineText: '用量子機器學習訓練古典神經網路', class: 'subject', column: 3, row: 9 },
        { text: '開幕總結', class: 'subject', column: 3, row: 10 },

        { text: '量子科技的新未來論壇', class: 'subject', column: 4, row: 5 },
        { text: 'break', class: 'subject', column: 4, row: 6 },
        { text: '量子基礎工作坊', class: 'subject', column: 4, row: '7 / 9' },
        { boldText: '黃琮暐', nextLineText: '量子科普主題分享', class: 'subject', column: 4, row: 9 },
        { text: '快樂回家', class: 'subject', column: '4 / 7', row: 10 },

        { text: '量子進程展', class: 'subject', column: 5, row: '2 / 10' },

        { text: '合作廠商交流', class: 'subject', column: 6, row: '2 / 10' },
      ],
      positionCells: [
        { type: 'icon', src: require('@/assets/position-container-icon.png'), class: 'icon', column: 1, row: 1 },
        { type: 'header', code: '4A', text: '區域', class: 'header', column: 2, row: 1 },
        { type: 'header', code: '4B', text: '區域', class: 'header', column: 3, row: 1 },
        { type: 'header', code: '4C', text: '區域', class: 'header', column: 4, row: 1 },
        { type: 'header', code: '4D', text: '區域', class: 'header', column: 5, row: 1 },
        { type: 'header', code: '4E', text: '區域', class: 'header', column: 6, row: 1 },

        { type: 'time', startTime: '8:30', endTime: '9:00', class: 'time', column: 1, row: 2 },
        { type: 'time', startTime: '9:10', endTime: '10:00', class: 'time', column: 1, row: 3 },
        { type: 'time', startTime: '10:10', endTime: '11:00', class: 'time', column: 1, row: 4 },
        { type: 'time', startTime: '11:10', endTime: '12:00', class: 'time', column: 1, row: 5 },
        { type: 'time', startTime: '12:00', endTime: '13:20', class: 'time', column: 1, row: 6 },
        { type: 'time', startTime: '13:30', endTime: '14:20', class: 'time', column: 1, row: 7 },
        { type: 'time', startTime: '14:30', endTime: '15:20', class: 'time', column: 1, row: 8 },
        { type: 'time', startTime: '15:30', endTime: '16:20', class: 'time', column: 1, row: 9 },
        { type: 'time', startTime: '16:30', endTime: '17:00', class: 'time', column: 1, row: 10 },

        { text: '海報競賽/展示', class: 'subject', column: 2, row: '2 / 10' },

        { text: '操作遊玩', class: 'subject', column: 3, row: '3 / 5' },
        { text: '競賽說明', class: 'subject', column: 3, row: 5 },
        { text: '操作遊玩', class: 'subject', column: 3, row: '6 / 9' },
        { text: '電玩決賽', class: 'subject', column: 3, row: 9 },

        { type: 'image', src: require('@/assets/schedule-cat-1.png'), class: 'image', column: 4, row: '3 / 5'},
        { text: '桌長開講', class: 'subject', column: 4, row: '5 / 9' },

        { text: '茶點用餐區', class: 'subject', column: 5, row: '2 / 10' },
        { text: '量子成果展示區', class: 'subject', column: 6, row: '2 / 10' },

        { text: '快樂回家', class: 'subject', column: '2 / 7', row: 10 },
      ]
    }
  },
  methods: {
    toGridCellStyle (column, row) {
      return {
        gridColumn: column,
        gridRow: row
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables.scss';

.content-block {
  padding: 2rem 0;

  > .content {
    max-width: 1000px;
    margin: auto;
  }

  > h2 {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -3rem;
      background-color: #FEEA0F;
      height: 100%;
      width: 2rem;
    }
  }
}

.schedule-container,
.position-container {
  grid-template-columns: repeat(6, 1fr);
  display: grid;
  grid-template-rows: 2rem repeat(9, 5rem);
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 4rem;

  > .cell {
    position: relative;

    > p {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      text-align: center;
      margin: 0;

      > b {
        font-size: 1.2rem;
      }
    }

    &.icon {
      position: relative;

      > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 32px;
      }
    }

    &.header {
      text-align: center;
      font-weight: bold;
      background-color: #FEEA0F;
      border: 1px solid black;
      border-radius: 0.5rem;
    }

    &.time {
      text-align: center;
      background-color: #898989;
      color: white;
      clip-path: polygon(
        0 10%,
        5% 0,
        95% 0,
        100% 10%,
        100% 90%,
        95% 100%,
        5% 100%,
        0% 90%
      );

      > p {
        display: flex;
        justify-content: center;
      }
    }

    &.subject {
      background-color: #DDDDDD;
    }

    &.image {
      text-align: center;
      > img {
        height: 100%;
        transform: translateY(0.5rem);
      }
    }
  }
}

.schedule-container {
  grid-template-columns: 1fr 1fr 1.75fr 1.75fr 1fr 1fr;
  position: relative;

  > .cat {
    position: absolute;

    &.center-top {
      right: 10%;
      top: 25%;
      width: 20%;
      transform: rotate(30deg);
    }

    > img {
      width: 100%;
    }
  }
}

.position-container {
  position: relative;

  > .cat {
    position: absolute;

    &.right-bottom {
      right: 5%;
      bottom: 20%;
      width: 20%;
      transform: rotate(150deg) scaleY(-1);
    }

    &.left-bottom {
      left: 20%;
      bottom: 15%;
      width: 20%;
      transform: rotate(315deg);
    }

    > img {
      width: 100%;
    }
  }
}

.article-block {
  margin-bottom: 2rem;

  > article {
    > h3 {
      font-size: 1.6rem;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    > h4 {
      font-size: 1.2rem;
      margin-top: 0;
      margin-bottom: 0.25rem;
    }

    > p {
      line-height: 2.5rem;
      margin-top: 0;
    }
  }
}

.article-block-with-image {
  display: flex;
  flex-wrap: wrap;

  > img {
    flex: 0 0 auto;
    width: 40%;
    padding-right: 1rem;
  }
  
  > article {
    flex: 0 0 auto;
    width: 60%;
    padding-left: 1rem;
  }
}

.full-width-image {
  display: block;
  width: 100%;
  margin-bottom: 2rem;
}

@media (max-width: $lg-breakpoint) {
  .content-block {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .article-block-with-image {
    > img {
      width: 100%;
      padding-right: 0;
    }
    
    > article {
      width: 100%;
      padding-left: 0;
    }
  }
}

@media (max-width: $xl-breakpoint) {
  .schedule-container,
  .position-container {
    > .cell {
      &.time {
        border-radius: 0.5rem;
        clip-path: none;
        > p {
          flex-direction: column;

          > span:nth-child(2) {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}

@media (max-width: $sm-breakpoint) {

  .schedule-container,
  .position-container {
    font-size: 0.8rem;
    gap: 0.25rem;

    > .cell {
      > p {
        > b {
          font-size: 1rem;
        }
      }

      &.icon {
        > img {
          width: 100%;
        }
      }

      &.header {
        > p {
          display: flex;
          flex-direction: column;
        }
      }

      &.image {
        > img {
          width: 75%;
        }
      }
    }
  }

  .schedule-container {
    grid-template-rows: 4rem repeat(9, 5rem);

    > .cat {
      &.center-top {
        right: 0;
        top: 25%;
        width: 40%;
      }
    }
  }

  .position-container {
    grid-template-rows: 3rem repeat(9, 5rem);

    > .cat {

      &.right-bottom {
        right: 5%;
        bottom: 10%;
        width: 40%;
      }

      &.left-bottom {
        left: 10%;
        bottom: 25%;
        width: 40%;
      }
    }
  }
}

</style>
