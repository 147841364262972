import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '../views/IndexView.vue'
import MapView from '../views/MapView.vue'
import AgendaView from '../views/AgendaView.vue'
import InfoView from '../views/SignupView.vue'
import NoticeView from '../views/NoticetView.vue'
import PartnersView from '../views/PartnersView.vue'

const routes = [
  {
    path: '/',
    name: 'IndexView',
    component: IndexView,
    meta: {
      text: '年會活動'
    }
  },
  {
    path: '/agenda',
    name: 'AgendaView',
    component: AgendaView,
    meta: {
      text: '議程表'
    }
  },
  {
    path: '/map',
    name: 'MapView',
    component: MapView,
    meta: {
      text: '會場地圖'
    }
  },
  {
    path: '/signup',
    name: 'SignupView',
    component: InfoView,
    meta: {
      text: '報名資訊'
    }
  },
  {
    path: '/notice',
    name: 'NoticeView',
    component: NoticeView,
    meta: {
      text: '注意事項'
    }
  },
  {
    path: '/partners',
    name: 'PartnersView',
    component: PartnersView,
    meta: {
      text: '合作夥伴'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, _from, next) => {
  document.title = `qracon 2024 - ${to.meta.text}`
  next()
})

export default router
