<template>
  <banner-block></banner-block>
  <div class="middle-block">
    <div class="content">
      <h2>QRACON年會注意事項</h2>
      <div class="notice-card">
        <article>
          <h3>年會行為準則</h3>
          <p>
            這些行為準則旨在營造一個尊重、合作和學術交流的環境，
            讓每位參與者都能充分參與、享受活動，共同創造一個成功而有意義的年會。
          </p>
        </article>
      </div>
      <div class="notice-card right">
        <article>
          <h3>尊重和禮貌</h3>
          <p>
            在年會期間，我們尊重每位參與者的觀點和意見。
            我們要保持友善、尊重和禮貌的態度，避免言語或行為上的冒犯。
          </p>
        </article>
      </div>
      <div class="notice-card">
        <article>
          <h3>知識和分享</h3>
          <p>
            年會是個學術交流的平台，鼓勵參與者積極分享知識、經驗和研究成果。
            同時也應該保持開放的態度，願意學習和聆聽他人的觀點。
          </p>
        </article>
      </div>
      <div class="notice-card right">
        <article>
          <h3>合作與辯論</h3>
          <p>
            我們鼓勵參與者之間的合作和建設性的辯論。我們應該尊重他人的觀點，
            並以理性和事實為基礎進行討論，避免人身攻擊或情緒化的言辭。
          </p>
        </article>
      </div>
      <div class="notice-card">
        <article>
          <h3>知識產權</h3>
          <p>
            我們要尊重他人的知識產權和研究成果。
            在年會期間，不得未經授權地使用他人的資料、圖片或研究成果。
          </p>
        </article>
      </div>
      <div class="notice-card right">
        <article>
          <h3>尊重隱私</h3>
          <p>
            我們應該尊重參與者的隱私權。
            不得未經授權地錄製、攝影或公開他人的演講或發言。
          </p>
        </article>
      </div>
      <div class="notice-card">
        <article>
          <h3>安全第一</h3>
          <p>
            我們要遵守年會場地的安全規定和指示。
            在任何緊急情況下，應該聽從工作人員的指示並保持冷靜。
          </p>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import BannerBlock from '../components/BannerBlock.vue'

export default {
  name: 'NoticeView',
  components: { BannerBlock }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables.scss';

.middle-block {
  background-image: url('../assets/notice-background.png');
  background-size: cover;
  background-repeat: repeat-y;
  background-position: top;
  min-height: 100vh;
}

.content {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  max-width: 720px;
  margin: auto;

  > h2 {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -3rem;
      background-color: #FEEA0F;
      height: 100%;
      width: 2rem;
    }
  }
}

.notice-card {
  background-color: #FEEA0F;
  border: 1px solid black;
  width: 55%;
  box-shadow: 7px 10px white, 7px 10px 0px 0.5px black;
  padding: 1rem;
  margin-bottom: 2rem;

  &.right {
    margin-left: auto;
  }

  > article {
    > h3 {
      margin-top: 0;
    }

    > p {
      margin: 0;
      line-height: 2rem;
    }
  }
}

@media (max-width: $lg-breakpoint) {
  .middle-block {
    background-size: contain;
  }

  .notice-card {
    width: 75%;
  }
}

</style>
